 @media (min-width: 576px) {
   .ms-sm-2 {
     margin-left: 8px;
   }

   .d-sm-flex {
     display: flex;
     align-items: center;
     justify-content: flex-end;
   }
 }

 @media (min-width: 768px) {

   .layout-dashboard .header-control {
     margin-top: 0;
   }

   .layout-dashboard .header-control .header-search {
     margin: 0 7px;
   }

   .layout-dashboard .header-control .btn-sign-in span {
     display: inline;
   }

   .profile-nav-bg {
     margin-top: -87.8px;
   }

   .card-profile-head {
     margin: -53px 24px 24px;
   }
 }

 @media (min-width: 992px) {
   .layout-dashboard .ant-layout-header {
     margin: 0px;
     padding: 20px 25px 20px;
   }

   /* .layout-dashboard .ant-layout-header + main.ant-layout-content.content-ant {
    padding-top: 30px;
  } */
   .content-ant:has(> .page-top-space) {
     padding-top: 0px !important;
   }

   .layout-dashboard .ant-layout-footer {
     margin: 0;
   }

   .layout-dashboard .ant-layout-sider.sider-primary {
     margin: 20px 0 0 20px;
     padding: 13px 20px;
     /* height: 100vh; */
   }

   .layout-dashboard .ant-layout {
     width: auto;
     flex-shrink: 1;
     margin-left: 0px;
   }

   .layout-dashboard .header-control .sidebar-toggler {
     display: none;
   }

   .ant-modal-body .ant-form-item .ant-row.ant-form-item-row {
     margin: 0 !important;
   }

   .footer-menu ul {
     justify-content: center;
   }

   footer.ant-layout-footer {
     padding: 0px 15px;
     text-align: center;
   }

   .project-ant {
     display: block;
   }

   .ant-filtertabs {
     text-align: right;
   }

   .rightside-top span.ant-input-affix-wrapper {
     width: 200px;
   }

   .rightside-top {
     justify-content: flex-end;
   }

   header.ant-layout-header.subheader {
     padding: 0px 24px 0px 0px;
   }

   main.ant-layout-content.content-ant {
     padding: 0 15px;
   }

   .ant-row {
     margin-left: -12px;
     margin-right: -12px;
   }

   span.ismobile {
     display: inline-block;
   }

   .bar-chart {
     width: 100% !important;
     max-width: 100%;
   }

   .full-width {
     max-width: 100% !important;
   }

   .bar-chart {
     width: 100% !important;
   }

   .icon-move-right {
     margin-bottom: 15px;
   }

   .pageheader {
     display: block;
   }

   .ant-cret img {
     width: 100%;
     height: 300px;
     object-fit: cover;
   }

   .card-billing-info.ant-card .ant-card-body {
     display: flex;
   }

   .layout-dashboard-rtl {
     overflow: auto;
   }

   .layout-dashboard-rtl .ant-layout-sider.sider-primary {
     margin: 20px 20px 0 0;
     height: calc(100vh - 20px);
   }

   .layout-dashboard-rtl .ant-layout-sider.sider-primary {
     right: 0;
     left: auto;
   }

   .layout-dashboard-rtl .ant-layout-sider.sider-primary {
     right: 0;
     left: auto;
   }

   .layout-dashboard-rtl .ant-layout {
     margin-right: 270px;
     margin-left: 0;
   }

   .layout-dashboard-rtl .ant-layout-footer {
     margin: 0 20px 20px 0;
   }

   .ant-modal-body form#create .ant-row {
     margin: 0 !important;
   }
 }

 @media (min-width:1569.98px) {

   .layout-dashboard .ant-layout-header {
     padding: 40px 40px 30px;
   }

   main.ant-layout-content.content-ant {
     padding: 0 28px;
   }
 }

 @media (max-width:1569.98px) {

   .btnStyle {
     padding: 12px 15px !important;
     font-size: 14px !important;
   }

   :root {
     --contactWidth: 400px;
   }

   .signin-box h1 {
     font-size: 28px !important;
   }

   .SectionMain>div {
     font-size: 28px;
   }

   .ant-card-body {
     padding: 18px !important;
   }

   .ant-card.tablespace .ant-card-body {
     padding: 0 !important;
   }

   .ant-table-thead>tr>th {
     font-size: 12px !important;
   }

   .modal_title_cls {
     font-size: 28px;
   }

   .number span {
     font-size: 15px;
   }

   .sub_title p {
     font-size: 15px;
   }

   .ftp_text {
     font-size: 14px;
     margin-bottom: 15px;
   }

   .number h3.ant-typography {
     font-size: 28px;
     line-height: 28px;
     margin-bottom: 10px;
   }

   .graph-title .ant-typography {
     font-size: 18px;
   }

   .tab_title span:first-child {
     font-size: 20px;
     margin-bottom: 5px;
   }

   .home-card .ant-tabs.ant-tabs-top.main_tabs .ant-tabs-tab-btn {
     padding: 10px 10px !important;
   }

   .home_progress {
     padding: 5px 30px;
   }

   .ant-tabs.ant-tabs-top.custom_tabs.main_tabs .ant-tabs-nav {
     flex: 0 0 160px;
     max-width: 160px;
   }

   .ant-tabs.ant-tabs-top.custom_tabs.main_tabs .ant-tabs-content-holder {
     flex-basis: calc(100% - 170px);
     max-width: calc(100% - 160px);
   }

   .home-card .ant-tabs.ant-tabs-top.custom_tabs.main_tabs .ant-tabs-tab-btn {
     margin-bottom: 5px;
   }
 }

 @media (max-width:1480.98px) {

   .customerMain .ant-card-extra,
   .specialistMain .ant-card-extra {
     flex-direction: column;
     align-items: unset;

   }

   .customerMain .ant-card-extra .button_group,
   .specialistMain .ant-card-extra .button_group {
     justify-content: end;

   }
 }

 @media (max-width:1391.98px) {
   .sign-up-bg section.ant-layout.signup-page {
     max-width: 100%;
   }

   .signin_img img {
     max-width: 100%;
   }

   .ant-card-head-wrapper .ant-card-extra {
     gap: 5px !important;
   }

   /* .ant-card-head-wrapper .ant-card-extra .ant-btn {
      padding: 0 10px !important;
      font-size: 12px;
  } */
   .btnStyle {
     font-size: 14px !important;
     line-height: 16px !important;
     letter-spacing: 0.04em !important;
     padding: 12px 18px !important;
   }

   .ant-card-head-title h4 {
     font-size: 22px;
   }

   .SectionMain>div {
     font-size: 24px;
   }
 }

 @media (max-width:1199.98px) {
  .tab_inner_tit h3{
    margin-bottom: 10px;
  }
   .sub_title p {
     font-size: 13px;
   }

   .bussiness_year {
     margin-left: 15px;
   }

   .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
     min-width: 100px;
   }

   .number span {
     font-size: 14px;
   }

   :root {
     --contactWidth: 300px;
   }

   /* .ant-card-head-wrapper {
      flex-direction: column !important;
  } */
   .tablespace .ant-card-head-title {
     /* width: 100%; */
     font-size: 16px;
     padding-bottom: 0 !important;
     line-height: 1.5;
   }

   .ant-card-head-wrapper .ant-card-extra {
     padding-top: 0 !important;
   }

   .vehicleMake .ant-card-head-wrapper .ant-card-extra,
   .vehicleModal .ant-card-head-wrapper .ant-card-extra,
   .vehicleType .ant-card-head-wrapper .ant-card-extra,
   .categoryService .ant-card-head-wrapper .ant-card-extra,
   .brandManager .ant-card-head-wrapper .ant-card-extra,
   .productAttribute .ant-card-head-wrapper .ant-card-extra,
   .productCaralogue .ant-card-head-wrapper .ant-card-extra,
   .quoteManagement .ant-card-head-wrapper .ant-card-extra,
   .blog .ant-card-head-wrapper .ant-card-extra,
   .bannerMain .ant-card-head-wrapper .ant-card-extra {
     flex-direction: row;
   }

   .ant-card-head-wrapper .ant-card-extra {
     flex-direction: column;
     gap: 12px;
     align-items: end;
   }

   .ant-card-head {
     padding: 12px 18px 12px !important;
   }

   .ant-card-head-title h4 {
     font-size: 20px;
   }

   .title-left p {
     font-size: 14px;
     line-height: 1.4;
   }

   .btnStyle {
     font-size: 13px !important;
     line-height: 15px !important;
     letter-spacing: 0.04em !important;
     padding: 8px 13px !important;
   }

   .tab-upload-wrap {
     flex-wrap: wrap;
   }
 }

 @media (max-width: 991.98px) {
   .tabLogo {
     padding-left: 40px;
     width: 100%;
     max-width: 170px;
   }
   .layout-dashboard .header-control {
    gap: 10px !important;
    margin-top: 10px !important;
}
   .layout-dashboard .header-control button.ant-btn.ant-btn-link.sidebar-toggler {
     position: absolute;
     left: 0;
     top: -45px;
   }

   .layout-dashboard .header-control button.ant-btn.ant-btn-link.sidebar-toggler {
     padding-right: 0 !important;
   }

   .layout-dashboard .header-control button.ant-btn.ant-btn-link.sidebar-toggler svg {
     height: 22px;
     width: 22px;
   }

   .layout-dashboard .ant-layout-header {
     margin: 0;
     border-bottom: solid 1px #c7c7c7;
     border-radius: 0;
     padding: 16px;
     margin-bottom: 24px;
   }

   .notification-header {
     position: absolute;
     top: -40px;
     right: 55px;
   }

   .profileDropdownMain {
     margin-left: 0;
     position: absolute;
     top: -50px;
     right: 0px;
   }

   .userImg {
     height: 40px;
     width: 40px;
   }

   .profileDropdownMain .ant-image {
     height: 40px !important;
     width: 40px !important;
     margin: 0 !important;
   }

   .Collector-details-first p {
     flex: 0 0 40%;
   }

   .ant-form-item.assign_role_checkbox label.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item {
     flex: 0 0 33.33%;
     max-width: 33.33%;
   }

   .sign-up-bg section.ant-layout.signup-page {
     padding: 30px;
     max-width: 100%;
   }

   .ant-col.padding-cls {
     padding-right: 0 !important;
   }

   .layout-dashboard .header-control button.ant-btn.ant-btn-link.sidebar-toggler svg path {
     fill: #000 !important;
   }

   .ant-space.ant-space-horizontal.ant-space-align-baseline .ant-space.ant-space-horizontal.ant-space-align-baseline {
     flex: 0 0 33.33%;
     max-width: 33.33%;
   }

   .edit-page-wrap .ant-row.ant-form-item-row {
     margin: 0;
   }

   .edit-page-wrap .ant-row {
     margin: 0;
   }

   .ant-form-item.add-types-cls {
     flex: 0 0 33.33%;
     max-width: 33.33%;
   }

   .ant-form-item.add-types-cls {
     flex: 0 0 33.33%;
     max-width: 33.33%;
   }

   .layout-dashboard .header-control button.ant-btn.ant-btn-link.sidebar-toggler {
     padding-right: 0 !important;
   }

   .layout-dashboard .ant-row {
     padding: 0 !important;
     margin: 0 !important;
   }

   footer.ant-layout-footer {
     width: 100%;
   }

   .selectQuote {
     flex-direction: column;
   }

   .SectionMain>div {
     font-size: 20px;
   }

   .modal_title_cls {
     font-size: 22px;
     line-height: 1.4;
     margin-bottom: 30px;
   }

   .modal_sub_title_cls {
     font-size: 18px;
   }

   .sm-padding-0 {
     padding: 0px !important;
   }

 }

 @media (max-width: 767.98px) {
  .Collector-details-first{
    flex-wrap: wrap;
  }
   .ant-table-tbody>tr>td,
   .ant-table-thead>tr>th {
     padding: 10px 12px !important;
   }

   .layout-dashboard .ant-layout-content {
     padding: 0;
     margin: 0 0px 0;
   }

   .ant-form-item.assign_role_checkbox label.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item {
     flex: 0 0 50%;
     max-width: 50%;
   }

   .add-btn {
     padding: 0;
   }

   .ant-space.ant-space-horizontal.ant-space-align-baseline .ant-space.ant-space-horizontal.ant-space-align-baseline {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .ant-btn.ant-btn-dashed.ant-btn-block {
     padding: 10px 10px;
     width: 100%;
   }

   .ant-form-item.add-types-cls {
     margin: 0 0 15px 0 !important;
   }

   .ant-space-item {
     margin: 0 !important;
   }

   .ant-space.ant-space-horizontal.ant-space-align-baseline .ant-row {
     margin: 0;
   }

   .ant-space.ant-space-horizontal.ant-space-align-baseline {
     padding: 0;
   }

   .ant-space.ant-space-horizontal.ant-space-align-baseline .ant-space.ant-space-horizontal.ant-space-align-baseline {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .anticon[tabindex] {
     right: 0;
   }

   .event_card_info {
     flex-direction: column;
   }

   .event_card_info .event-cont-dtl {
     margin-left: 0;
   }

   .event_card_info .event-cont-dtl .view-inner-cls.desc-wrap {
     flex-wrap: wrap;
   }

   .event-img-wrap {
     height: unset;
     flex: 0 0 100%;
   }

   .layout-dashboard.layout-dashboard-rtl .header-control {
     justify-content: flex-end;
   }

   .ant-card-head-wrapper .ant-card-extra {
     padding-top: 0 !important;
     flex-wrap: wrap;
     justify-content: start;
     /* align-items: start; */
   }

   .ant-card-head-wrapper {
     flex-direction: column !important;
   }

   .ant-card-head-wrapper .ant-card-head-title {
     padding-bottom: 0 !important;
     width: 100%;
   }

   /* 01/06/2023 */
   .layout-dashboard .header-control {
     margin-top: 0;
   }

   .layout-dashboard .header-control {
     margin-top: 0;
   }

   .ant-row {
     margin-right: 0 !important;
     margin-left: 0 !important;
   }

   /* .ant-col {
        padding: 0 !important;
    } */

   .view-main-list {
     padding-right: 0;
     border-right: 0;
     margin-bottom: 15px;
   }

   .layout-dashboard .header-control svg {
     margin-right: 0 !important;
   }

   .ant-btn.ant-btn-link.sidebar-toggler {
     margin-right: 0 !important;
     padding-right: 0 !important;
   }

   .ant-modal {
     top: 50px !important;
   }

   .card-profile-head .ant-card-head {
     padding: 0 10px !important;
   }

   .card-profile-head .ant-card-head-wrapper .ant-avatar-group .avatar-info h4 {
     font-size: 14px;
   }

   .card-profile-head .ant-card-head-wrapper .ant-avatar-group .avatar-info p {
     font-size: 12px;
   }

   .brandManager .ant-card-head-wrapper .ant-card-extra,
   .productAttribute .ant-card-head-wrapper .ant-card-extra,
   .categoryService .ant-card-head-wrapper .ant-card-extra,
   .quoteManagement .ant-card-head-wrapper .ant-card-extra,
   .blog .ant-card-head-wrapper .ant-card-extra {
     flex-wrap: nowrap;
   }

   .backArrowBtn {
     display: block;
   }

   .chatMainOuter {
     position: relative;
   }

   :root {
     --contactWidth: 100%;
   }

   .chatMainOuter .chatingOuter {
     width: 100%;
     position: absolute;
     top: 0;
     right: 0;
     border: none;
     transition: ease-in-out .3s;
     opacity: 1;
   }

   .chatMainOuter .chatingOuter:not(.active) {
     right: -100%;
     opacity: 0;
   }

   .chatMainOuter .contactOuter .contactBody,
   .chatMainOuter .chatingOuter .chatingBody {
     height: 0;
   }

   .SectionMain>div {
     font-size: 18px;
   }

   .cls-total.received-amt .sales-img-icon {
     width: 40px;
     height: 40px;
     margin-right: 5px;
     flex: 0 0 40px;
   }

   .ant-card.ant-card-bordered.collection_card {
     padding: 15px 10px; 
   }

   .sales-text-outer h4 {
     font-size: 14px;
   }

   .sales-text-outer h6 {
     font-size: 12px;
   }

   .Collector-details-first p {
     font-size: 12px;
     flex: 0 0 35%;
   }

   span.Collector-details {
     font-size: 12px;
   }
 }

 @media (max-width:575.98px) {

  .ant-card.ant-card-bordered.collection_card {
    margin-top: 10px;
  }
   .detail-social-text {
     margin: 10px 0px;
   }
   .tab-detail-inputs button.ant-btn.ant-btn-primary{
    height: 32px !important;
    padding: 10px 15px !important;
   }

   button.ant-btn.ant-btn-primary {
     height: 32px !important;
     padding: 10px 15px !important;
   }

   .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
     height: 32px !important;
     line-height: 18px;
   }

   .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
     color: #00000075;
     font-weight: 600;
     font-size: 12px;
   }

   .ant-select-single .ant-select-selector .ant-select-selection-item,
   .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
     line-height: 28px !important;
   }


   .tab_inner_tit h3 {
     font-size: 16px;
   }

   .ant-typography h4,
   div.ant-typography-h4,
   div.ant-typography-h4>textarea,
   h4.ant-typography {
     font-size: 14px !important;
   }

   .graph-title {
     flex-wrap: wrap;
   }

   .ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
     max-width: 300px;
     left: 0px;
   }

   .ant-form-item.assign_role_checkbox label.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .ant-card-head-wrapper .ant-card-extra button.ant-btn+span {
     width: 100%;
     display: block;
   }

   .ant-card-head-wrapper .ant-card-extra button.ant-btn+span .ant-upload.ant-upload-select.ant-upload-select-picture {
     width: 100%;
   }

   .ant-form-item.add-types-cls {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .ant-card-head-wrapper .ant-card-extra .ant-btn {
     width: 100%;
   }

   span.ant-input-group-wrapper.ant-input-search.ant-input-search-large.ant-input-group-wrapper-lg span.ant-input-wrapper.ant-input-group {
     max-width: 100%;
   }

   span.ant-input-group-wrapper.ant-input-search.ant-input-search-large.ant-input-group-wrapper-lg span.ant-input-wrapper.ant-input-group button.ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
     min-width: 50px;
   }

   .view-inner-cls h6 {
     font-size: 11px;
   }

   .view-inner-cls h5 {
     font-size: 12px;
   }

   .signin-box h5 {
     font-size: 14px !important;
     margin-bottom: 10px !important;
   }

   .signin-box h1 {
     font-size: 16px !important;
   }

   .ant-btn {
     padding: 5px 10px;
     font-size: 12px !important;
   }

   th,
   td {
     padding: 5px 3px !important;
     font-size: 12px !important;
   }

   .assignment-chart-list .ant-card.card-project p {
     font-size: 12px;
     padding: 0 10px;
   }

   .ant-breadcrumb-separator {
     margin: 0 3px;
   }

   span.ant-breadcrumb-link {
     font-size: 12px;
   }

   .ant-input {
     font-size: 12px;
   }

   .breadcrumbMain {
     display: none !important;
   }

   .header-control {
     justify-content: space-between !important;
   }

   .header-control .profileDropdownMain .ant-btn {
     order: 2 !important;
     text-align: left !important;
   }

   .ant-picker.ant-picker-range,
   .ant-picker.ant-picker-range.ant-picker-focused {
     width: 100%;
   }

   .searchOuter {
     flex-direction: column;
   }

   .ant-picker .ant-picker-input>input {
     width: 100%;
   }

   .mt-xs-2 {
     margin-top: 8px;
   }

   .brandManager .ant-card-head-wrapper .ant-card-extra,
   .categoryService .ant-card-head-wrapper .ant-card-extra {
     flex-wrap: wrap;
   }

   .view-inner-cls.other-image.productDetail .ant-image {
     width: 60px !important;
     height: 60px !important;
   }

   .logoOuter {
     height: 140px;
     width: 100px;
   }

   .ant-card-head-title h4 {
     font-size: 18px;
   }

   .ant-card-head-wrapper {
     gap: 10px;
     align-items: center;
   }

   .modal_title_cls {
     font-size: 18px;
     margin-bottom: 20px;
   }

   .modal_sub_title_cls {
     font-size: 15px;
   }
 }

 @media (max-width: 434px) {
   .ant-card.ant-card-bordered.collection_card {
     margin-top: 0px;
   }

   .topbar-user-img {
     flex-wrap: wrap;
   }

   .productAttribute .ant-card-head-wrapper .ant-card-extra,
   .quoteManagement .ant-card-head-wrapper .ant-card-extra,
   .blog .ant-card-head-wrapper .ant-card-extra {
     flex-wrap: wrap;
   }

   .w-xxs-100 {
     width: 100%;
   }
 }

 @media (max-width: 360px) {
   .button_group {
     flex-direction: column;
     width: 100%;
   }
 

 }